
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import {mask} from 'vue-the-mask'
import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { ActionsRe } from "@/store/enums/StoreResearchEnums";
import { date } from "yup/lib/locale";
import {active} from "@/core/data/genericData";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Quill from "quill/dist/quill.js";

export default defineComponent({
  name: "edit-categories-modal", 
  directives: {mask}, 
  props: {
    id: { required: true },
    // data: {
    //     type: Object
    // }
  },
  components: { 
      
    },    
  methods: {
    onChange() 
    { 
      this.formData['category_select'] = '' 
      }
  },

  setup(props) {    
    const active_list_data = active;
    
    // this.$forceUpdate();
    var tag_list: any[] = []
    var industry_type_list : any[] = []
    const router = useRouter();    
    var formData = ref({})
    const store = useStore();
    const setFormData = async (id) => {    
        
        var j = 0;

        try {

        var values = { "report_id": id}
         await store.dispatch(Actions.CUST_REPORT_EDIT, values).then(({ data }) => {       
          //  var quill = new Quill('#kt_forms_widget_1_editor', {
          //   //theme: 'snow'
          // });     
          // var descrip =  quill.root.innerHTML = data.report_description;
          formData.value = {
            id : data.report_id ,
            name: data.report_title,
            description:  data.report_description,
            tag_select: data.report_tags_json,
            parent_select: data.parent_category_id,
            category_select: data.category_id,
            active : data.active,
            };
            
          })
          .catch(({ response }) => {

            console.log(response);

          });
          
        } catch (e) {
          console.log(e);
        } 
        
    }

      let rawImg = ref() ;
    function encodeImagetoBase64L(element) {
        const image = element.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(image);
        
        reader.onload = (event) => {       
        var image_encode = String(reader.result)
        if (image_encode){
           var image_slice = image_encode.split(",")[1]
            rawImg.value = {
          file_name:element.target.files[0].name,
          file_data: image_slice,
          }
        }
        else{
          rawImg.value = {
          file_name:element.target.files[0].name,
          file_data:reader.result,
          }
        }
       
       };
      
      }

      let document_details = ref();
      function UploadDoc(element) {
        const doc = element.target.files[0];
        const reader1 = new FileReader();
        reader1.readAsDataURL(doc);
        reader1.onload = (event) => {
        document_details.value = {fname:element.target.files[0].name, fdata: reader1.result}
       }
     }
   
    const taglist = ref([]);
    const setTagData = async (data) => {
        //loading.value = true;
        try {
          var values = {"search_term": data}
          await store.dispatch(Actions.CUST_TAG_LIST, values).then(({ data }) => {
          taglist.value = data.page_data;
          //loading.value = false;
         
        })
        .catch(({ e }) => {
          console.log(e);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }
    
    const parentcat = ref([]);
     const setParentCatData = async () => {
        loading.value = true;
        try {
          var values = {}
          await store.dispatch(Actions.CUST_NEWS_PARENT_CATEGORY, values).then(({ data }) => {
          parentcat.value = data;
          loading.value = false;
         
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

     const childcat = ref([]);
     const setChildCatData = async () => {
      
        loading.value = true;
        try {
          var values = {
            "category_id": formData.value['parent_select']
          }
          await store.dispatch(Actions.CUST_NEWS_CHILD_CATEGORY, values).then(({ data }) => {
          childcat.value = data;
          loading.value = false;
         
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    onMounted( async () => {    
      setCurrentPageBreadcrumbs("Reports", []);  
      await setFormData(props.id);
      await setTagData(taglist.value)
      await setParentCatData()
      await setChildCatData()
      const editorId = "kt_forms_widget_1_editor";

      // init editor
      const options = {
        modules: {
          toolbar: {
            container: "#kt_forms_widget_1_editor_toolbar",
          },
        },
        theme: "snow",
      };

      // Init editor
      new Quill("#" + editorId, options);
      });  

    
    const formRef = ref<null | HTMLFormElement>(null);
    const editReportModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);    
    //const loadingHsn = ref<boolean>(false);

 const rules = ref({
      name: [
        {
          required: true,
          message: "Category Name is required",
          trigger: "change",  
          //pattern:"^[a-zA-Z]+$",        
        },        
      ],    
      description: [
        {
          required: true,
          message: "Description is required",
          trigger: "change",  
        }
      ],
      parent_select: [
        {
          required: true,
          message: "Parent Category is required",
          trigger: "change",  
        }
      ],
      shape_select: [
        {
          required: true,
          message: "Shape is required",
          trigger: "change",  
        }
      ],
      form_select: [
        {
          required: true,
          message: "Category Form is required",
          trigger: "change",  
        }
      ],
      stage_select: [
        {
          required: true,
          message: "Stage is required",
          trigger: "change",  
        }
      ],
      length_select: [
        {
          required: true,
          message: "Length is required",
          trigger: "change",  
        }
      ],
      industry_select: [
        {
          required: true,
          message: "Industry type is required",
          trigger: "click",  
        }
      ],
       tag_select: [
        {
          required: true,
          message: "Tag is required",
          trigger: "click",  
        }
      ],
      hsn_select: [
        {
          required: true,
          message: "Hsn is required",
          trigger: "change",  
        }
      ],
    });

  

    const addtag : any[] = [];
    const setCategoryData = async (data) => {   
      //var user = JSON.parse(JwtService.getToken());     
      var tag_id_list : any [] = []
      var tag_text_list : any [] = []
      for (let j = 0; j < data.tag_select.length; j++){
          if (data.tag_select[j]){
            tag_text_list.push(data.tag_select[j])
          }
      }
      for (let i=0; i<tag_text_list.length; i++)
      {
        const db_data = {
          "tag_name": tag_text_list[i],
          "created_user_id" : 1
          }
           await store.dispatch(Actions.CUST_ADD_TAG, db_data).then(({ data }) => {
           
           addtag.push(data.tag_id);
           
          })
      }
    
      const merge = tag_text_list;
      // var quill = new Quill('#kt_forms_widget_1_editor', {
      //   theme: 'snow'
      //     });     
      // var descrip = quill.root.innerHTML;
    
      const db_data = {
        "report_id": data.id,
        "report_title": data.name,
        "report_description": data.description,
        "thumbnail_image_name": rawImg.value.file_name,
        "thumbnail_image_data": rawImg.value.file_data,
        "doc_name": document_details.value.fname,
        "doc_data": document_details.value.fdata,
        "parent_category_id": data.parent_select,
        "category_id": data.category_select,
        "report_tags_json": merge,
        "active": data.active,
        }

        await store.dispatch(Actions.CUST_REPORT_SAVE, db_data).then(({ data }) => {
          
        if (data)
        {
            setTimeout(() => {
              loading.value = false;
              Swal.fire({
                text: "Report has been successfully Updated.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(editReportModalRef.value);  
                router.go(0)           
              });
            }, 2000)          

          } else {

            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
        })
      .catch(({ response }) => {
        console.log(response);        
      });
    };

   const submit = async () => {

      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate (async (valid) => {

        if (valid) {

          await setCategoryData(formData.value);
        
        } else {

          loading.value = false;
          return false;
        }

      });  
    }
    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      setTagData,
      setParentCatData,
      setChildCatData,
      encodeImagetoBase64L,
      UploadDoc,
      document_details,
      parentcat,
      childcat,
      rawImg,
      taglist,
      parent,
      editReportModalRef,
      tag_list,
      industry_type_list,
      active_list_data,
    };
  },
});
